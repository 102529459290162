import A11YSlider from "../../../../../../node_modules/a11y-slider/dist/a11y-slider.esm.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let sliders = $$(".slider-captioned__slider");

if (sliders.length > 0) {

  sliders.forEach(slider => {
    if (slider.children.length > 1 ) {

      slider.addEventListener('init', function(e) {
        let headline = $("img",e.detail.a11ySlider.activeSlide).getAttribute("data-headline");
        let paragraph = $("img",e.detail.a11ySlider.activeSlide).getAttribute("data-paragraph");
        $(".slider-captioned__content h3").textContent = headline;
        $(".slider-captioned__content p").textContent = paragraph;
      })

      slider.addEventListener('beforeChange', function(e) {
        if(e.detail.currentSlide !== e.detail.nextSlide) {
          $(".slider-captioned__content").classList.add("opacity-0");
        }
      })

      slider.addEventListener('afterChange', function(e) {
        let headline = $("img",e.detail.currentSlide).getAttribute("data-headline");
        let paragraph = $("img",e.detail.currentSlide).getAttribute("data-paragraph");
        $(".slider-captioned__content h3").textContent = headline;
        $(".slider-captioned__content p").textContent = paragraph;
        $(".slider-captioned__content").classList.remove("opacity-0");
      })

      new A11YSlider(slider, {
        slidesToShow: 1,
        dots: false,
        prevArrow: $(".slider-captioned__controls .prev"),
        nextArrow: $(".slider-captioned__controls .next")
      })
    }
  })
}
