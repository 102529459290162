const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const loadMoreSection = $(".person-articles .load-more");

if (loadMoreSection) {
  $("button", loadMoreSection).addEventListener("click", loadMore);
}

function loadMore() {
  $$(".person-articles div[itemprop='itemListElement']").forEach(article => {
    article.classList.remove("hidden");
  })
  loadMoreSection.classList.add("hidden");
}