// Vendor Libraries
//import $ from "../../../../node_modules/cash-dom/dist/cash.esm.js";
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { gsap } from "../../../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../../../node_modules/gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

gsap.to(".page-header", {
  scrollTrigger: {
    start: "1",
    toggleClass: { targets: ".page-header", className: "stuck" }
  }
});

document.addEventListener('mouseover', function (event) {
  let closest = event.target.closest(".page-header:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  closest.classList.add("open");
}, false);

document.addEventListener('mouseout', function (event) {
  let closest = event.target.closest(".page-header:not(.menu-open) nav > ul > .isParent");
  if (!closest) return;
  closest.classList.remove("open");
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header__menu-open, .page-header__menu-close");
  if (!closest) return;
  $("header.page-header").classList.toggle("menu-open");
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header__search-box");
  if (closest) return;
  let open = $(".page-header__search-box")?.classList.contains("open");
  if (!open) return;

  $(".page-header__search-box").classList.remove("open");
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header__search-open, .page-header__search-close");
  if (!closest) return;
  $(".page-header__search-box").classList.toggle("open");
  if (closest.classList.contains("page-header__search-open")) {
    setTimeout(function(){ $("#site-search").focus(); }, 500);
  }
  if (closest.classList.contains("page-header__search-close")) {
    $(".page-header__search-open").focus();
  }
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header a + button");
  if (!closest) return;

  let $toggle = closest;
  let $navItem = $toggle.closest(`li`);
  let $otherNavItems = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  $otherNavItems.forEach(item => {
    let button = $("button", item);
    item.classList.remove("open");
    if (button) {
      button.setAttribute(`aria-expanded`, `false`);
    }
  })

  $navItem.classList.toggle(`open`);
  if ($toggle.getAttribute(`aria-expanded`) === `true`) {
    $toggle.setAttribute(`aria-expanded`, `false`);
  } else {
    $toggle.setAttribute(`aria-expanded`, `true`);
  }
}, false);

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".page-header.menu-open nav .isParent > a");
  if (!closest) return;

  const $navItem = closest;
  const $parent = $navItem.parentNode;
  let siblings = Array.prototype.filter.call($navItem.parentNode.children, function(child){
    return child !== $navItem;
  });

  event.preventDefault();

  if ($parent.classList.contains(`open`)) {
    $parent.classList.remove(`open`);
  } else {
    $parent.classList.add(`open`)
    siblings.forEach(sibling => {
      sibling.classList.remove("open");
    })
  }
}, false);