// The imports will be hosted to the root "node_modules",
// and this package is under "@collections/accordion".
import "../../../../node_modules/details-element-polyfill/dist/details-element-polyfill.js";

/**
 * Handles keyboard navigations on the <summary>
 *
 * * <kbd>Down Arrow</kbd>: moves focus to next `<summary>` in the accordion,
 *   or the first `<summary>`.
 * * <kbd>Up Arrow</kbd>: moves focus to previous `<summary>` in the accordion,
 *   or the last `<summary>`.
 * * <kbd>Home</kbd> : moves focus to the first accordion header.
 * * <kbd>End</kbd> : moves focus to the last accordion header.
 * @param {KeyboardEvent} event
 */
function onKeyDownOnAccordion(event) {
  const target = /** @type {HTMLElement} */(event.target);
  const key = event.key;

  // Ignores if event is not coming from an accordion header.
  if (target.tagName !== "SUMMARY") {
    return;
  }

  // Ignore other keys
  if (["Down", "ArrowDown", "Up", "ArrowUp", "Home", "End"].indexOf(key) === -1) {
    return;
  }

  const accordion = target.closest(".accordion");
  const triggers = Array.from(accordion.querySelectorAll("summary"));
  const length = triggers.length;
  const index = triggers.indexOf(target);
  let newIndex;

  switch (key) {
    case "Down": // IE/Edge specific value
    case "ArrowDown":
      newIndex = (index + length + 1) % length;
      break;
    case "Up": // IE/Edge specific value
    case "ArrowUp":
      newIndex = (index + length -1) % length;
      break;
    case "Home":
      // Go to first accordion
      newIndex = 0;
      break;
    case "End":
      // Go to last accordion
      newIndex = length - 1;
      break;
  }

  triggers[newIndex].focus();
  event.preventDefault();
}

document.querySelectorAll(".accordion").forEach((accordion) => {
  accordion.addEventListener("keydown", onKeyDownOnAccordion);
});
