const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let clips = $$(".media__auto-clip video");
let controledClips = $$(".media__auto-clip:not(.hide-controls) video");
let clipPlayButtons = $$(".media__auto-clip:not(.hide-controls) .button--play");
let mediaPlayButtons = $$(".media__video-placement .button--play");
let articleIFrames = $$(".article-inline iframe");
let reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;
let mql = window.matchMedia('(min-width: 768px)');

function youtube_parser(url){
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
}

function vimeo_parser(url){
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    return match[1];
  }
}

if (mediaPlayButtons.length > 0) {
  mediaPlayButtons.forEach(button => {
    button.addEventListener("click", function(event) {
      event.preventDefault();
      let videoContainer = button.parentNode;
      let videoString = videoContainer.getAttribute("data-video");
      let formattedURL = videoString;
      if (videoString.indexOf("youtu") !== -1) {
        let ID = youtube_parser(videoString);
        if (ID) {
          formattedURL = `https://www.youtube.com/embed/${ID}?autoplay=1`;
        }
      } else if (videoString.indexOf("vimeo") !== -1) {
        let ID = vimeo_parser(videoString);
        if (ID) {
          formattedURL = `https://player.vimeo.com/video/${ID}?h=5d4560c5a6&color=00a7a1&title=0&byline=0&portrait=0&autoplay=1`;
        }
      }

      let videoEmbed = `<div class="video-wrapper"><iframe width="560" height="315" src="${ formattedURL }" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`
      videoContainer.innerHTML = videoEmbed;
    })
  })
}

if (articleIFrames.length > 0) {
  articleIFrames.forEach(iframe => {
    let wrapper = document.createElement('div');
    wrapper.setAttribute("class", "video-wrapper");
    iframe.parentNode.insertBefore(wrapper, iframe);
    wrapper.appendChild(iframe);
  })
}

if (controledClips.length > 0) {
  controledClips.forEach(clip => {
    clip.parentNode.addEventListener("click", function(event) {
      clip.parentNode.parentNode.classList.add("paused");
      clip.pause();
    })

    clip.addEventListener("keydown", function(event) {
      const key = event.key;
      if (["Enter", " "].indexOf(key) === -1) {
        return;
      }
      event.preventDefault();
      clip.parentNode.parentNode.classList.add("paused");
      clip.pause();
    })
  })
}

if (clipPlayButtons.length > 0) {
  clipPlayButtons.forEach(button => {
    button.addEventListener("click", function(e) {
      e.preventDefault();
      let video = $("video",button.parentNode);
      let source = $("video source",button.parentNode);
      button.parentNode.classList.remove("paused");

      if (source.getAttribute("src")) {
        video.play();
      } else {
        source.setAttribute("src", source.getAttribute("data-src"));
        video.load();
      }
    })
  })
}

if (clips.length > 0 && !reducedMotion && mql.matches) {
  document.addEventListener("DOMContentLoaded", function() {
    var lazyVideos = [].slice.call(clips);

    if ("IntersectionObserver" in window) {
      var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(video) {
          if (video.isIntersecting) {
            for (var source in video.target.children) {
              var videoSource = video.target.children[source];
              if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                videoSource.src = videoSource.dataset.src;
                video.target.parentNode.parentNode.classList.remove("paused");
              }
            }

            if (video.target.getAttribute("loop") === "false") {
              video.target.removeAttribute("loop");
            }

            video.target.load();
            lazyVideoObserver.unobserve(video.target);
          }
        });
      });

      lazyVideos.forEach(function(lazyVideo) {
        lazyVideoObserver.observe(lazyVideo);
      });
    }
  });
}