const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

var $tableSlides = $$(".table-accordion tbody tr");
var $tableSlideHeaders = $$(".table-accordion tbody tr th");

if ($tableSlides.length > 0) {
  $tableSlides[0].classList.add("active");

  $tableSlideHeaders.forEach(header => {
    header.addEventListener("click", function(e) {
      e.preventDefault();
      header.parentNode.classList.toggle("active");
    });
  })
}