const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

class AnchorNav {
  constructor(component, options = {}) {
    const navLinks = $$("ul a", component);

    this.navLinks = navLinks;
    this.clickLink = this.clickLink.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
    this.component = component;
    this.anchors = document.querySelectorAll("a[id]");

    // navLinks.forEach(link => {
    //   link.addEventListener("click", this.clickLink);
    // });

    const observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: null,
        rootMargin: `15% 0% -75% 0%`,
        threshold: 0
      }
    );

    this.lastAnchorId = ``;
    this.anchors.forEach((element) => {
      const id = element.id;
      if (component.querySelector(`a[href^="#${ id }"]`)) {
        observer.observe(element);
      }
    });
  }

  handleIntersection(entries, _observer) {
    let { component } = this;
    entries.forEach(({ target, isIntersecting, intersectionRatio }) => {
      const id = target.id;
      const link = component.querySelector(`a[href^="#${ id }"]`);

      if (isIntersecting && intersectionRatio === 1) {
        this.toggleActive(`#${id}`);
      }
    });
  }

  toggleActive(id) {
    const link = $(`a[href="${id}"]`, this.component);
    this.navLinks.forEach(link => {
      link.classList.remove("active");
    });
    link.classList.add("active");
  }

  clickLink(event) {
    event.preventDefault();
    const id = event.target.getAttribute("href");
    this.scrollTo(id);
    //this.toggleActive(id);
  }

  scrollTo(id) {
    let { component } = this;
    let $el = $(id);
    let $header = $("header[role='banner']");
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (!$el) {
      return;
    }

    let headerOffset = 0 + $header.offsetHeight + component.offsetHeight;
    if ($("body").classList.contains("samadmin")) {
      headerOffset = headerOffset + 97;
    }

    window.scroll({
      top: $el.getBoundingClientRect()["top"] + scrollTop
            - headerOffset
            - 45 /* Extra padding for visual look */,
      behavior: 'smooth'
    });
  };
}

$$(".anchor-nav, .process-nav").forEach(anchorNav => {
  new AnchorNav(anchorNav);
});

export default AnchorNav;