import dialogPolyfill from '../../../../node_modules/dialog-polyfill/dist/dialog-polyfill.esm.js';
const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

const lightbox = $("#lightbox");

if (lightbox) {
  const lightboxContent = $(".lightbox-content", lightbox);
  const lightboxClose = $("button.close", lightbox);
  dialogPolyfill.registerDialog(lightbox)

  document.addEventListener("click", event => {
    let target = event.target;
    let content = "";

    if (target.matches(`a[rel*="lightbox"]`)) {

      const editor = target.closest(".mce-content-body");
      if (editor) {
        return;
      }

      event.preventDefault();

      let hostname = new URL(target.href).hostname;

      switch (hostname) {
        case "youtube.com":
        case "www.youtube.com":
        case "youtu.be":
        case "www.youtu.be":
          let videoRegex = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
          let videoID = target.href.match(videoRegex)[1];
          content = `<div class="video-wrapper">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>`;
      }

      lightboxContent.innerHTML = "";

      if (content !== '') {
       lightboxContent.innerHTML = content;
       lightbox.showModal();
      }
    }
  })

  lightbox.addEventListener("click", event => {
    // Clicking on the backdrop.
    if (event.target == lightbox || event.target == $("button.close",lightbox)) {
      lightbox.close();
    }
  });

  lightbox.addEventListener("close", event => {
    lightboxContent.innerHTML = "";
  });
}