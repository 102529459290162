const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { gsap } from "../../../../../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../../../../../node_modules/gsap/ScrollTrigger.js";
gsap.registerPlugin(ScrollTrigger);

class TheProcess {
  constructor(component, options = {}) {
    this.container = component;
    this.processHeader = $(".grid-process__header");
    this.processFooter = $(".grid-process__col--footer");
    this.imageContainers = $$(".grid-process__col--image");
    this.contentContainers = $$(".grid-process__col:not(.grid-process__col--image):not(.grid-process__col--footer)");
    this.anchors = $$(".grid-process__col a[id]")
    this.processLine = $(".grid-process__line");
    this.rendered = false;
    this.initProcessNav = this.initProcessNav.bind(this);
    this.loadLines = this.loadLines.bind(this);
    this.initLoad = this.initLoad.bind(this);
    this.initSimpleLines = this.initSimpleLines.bind(this);
    this.initLoad();
    this.initProcessNav();
    this.initSimpleLines();

    const throttled = this.throttle(this.initLoad, 500);
    window.addEventListener('resize', throttled);
  }

  throttle(func, wait = 100) {
    let timer = null;
    return function(...args) {
      if (timer === null) {
        timer = setTimeout(() => {
          func.apply(this, args);
          timer = null;
        }, wait);
      }
    };
  }

  initProcessNav () {
    let { anchors } = this;
    let navContainer = document.createElement('div');
    navContainer.setAttribute("class", "process-nav");
    let nav = `<div class="anchor-nav__wrap"><ul>`;

    anchors.forEach(function(anchor, index){
      let containerID = anchor.id;
      nav = nav + `<li><a href="#${containerID}">${containerID}</a></li>`;
      if (index < anchors.length - 1) {
        nav = nav + `<li><span class="icon-right-dir"></span></li>`
      }
    })

    nav = nav + `</ul></div></div>`;
    navContainer.innerHTML = nav;

    $("main.page-content").prepend(navContainer);

    ScrollTrigger.create({
      trigger: ".grid-process__grid",
      start: "top top",
      onEnter: function() {
        navContainer.classList.add("reveal");
      },
      onEnterBack: function() {
        navContainer.classList.add("reveal");
      },
      onLeave: function() {
        navContainer.classList.remove("reveal");
      },
      onLeaveBack: function() {
        navContainer.classList.remove("reveal");
      }
    });
  }

  initSimpleLines() {
    let { imageContainers, processFooter } = this;

    ScrollTrigger.create({
      trigger: processFooter,
      start: "top bottom",
      onEnter: function() {
        $(".grid-process__dashed-line-segment", processFooter).classList.add("animate");
      }
    });

    imageContainers.forEach(function(image, index){
      ScrollTrigger.create({
        trigger: image,
        start: "top bottom",
        onEnter: function() {
          $(".grid-process__line-segment", image).classList.add("animate");
        }
      });
    })
  }

  initLoad() {
    if (!this.rendered) {
      let mql = window.matchMedia('(min-width: 1440px)');
      if (mql.matches) {
        this.rendered = true;
        this.loadLines();
      }
    }
  }

  loadLines() {
    let { contentContainers, imageContainers } = this;
    let rows = [];
    let processFooterTop = this.processFooter.offsetTop;
    let containerHeight = parseFloat(getComputedStyle(this.container, null).height.replace("px", ""));

    imageContainers.forEach(function(image, index){
      let content = contentContainers[index];
      let imageHeight = parseFloat(getComputedStyle(image, null).height.replace("px", ""));
      let imageTop = image.offsetTop;
      let imageMidPoint = imageTop + imageHeight/2;
      let contentHeight = parseFloat(getComputedStyle(content, null).height.replace("px", ""));
      let contentTop = content.offsetTop;
      let contentTargetPoint = contentTop + contentHeight + 59.5;

      rows.push({
        imageTarget: imageMidPoint,
        contentTarget: contentTargetPoint
      })
    })

    let lineDef1 = `M550 0 L550 196 L1099 196
                    L1099 ${rows[0].imageTarget}
                    L845 ${rows[0].imageTarget}`;
    let lineDef2 = `M845 ${rows[0].imageTarget}
                    L645 ${rows[0].imageTarget}
                    L645 ${rows[0].contentTarget}
                    L1 ${rows[0].contentTarget}
                    L1 ${rows[1].imageTarget}
                    L245 ${rows[1].imageTarget}`;
    let lineDef3 = `M245 ${rows[1].imageTarget}
                    L445 ${rows[1].imageTarget}
                    L445 ${rows[1].contentTarget}
                    L1099 ${rows[1].contentTarget}
                    L1099 ${rows[2].imageTarget}
                    L845 ${rows[2].imageTarget}`;
    let lineDef4 = `M845 ${rows[2].imageTarget}
                    L645 ${rows[2].imageTarget}
                    L645 ${rows[2].contentTarget}
                    L1 ${rows[2].contentTarget}
                    L1 ${rows[3].imageTarget}
                    L200 ${rows[3].imageTarget}`;
    let dashedLineDef = `M300 ${rows[3].imageTarget}
                        L445 ${rows[3].imageTarget}
                        L445 ${rows[3].contentTarget}
                        L550 ${rows[3].contentTarget}
                        L550 ${processFooterTop + 30}`;

    let svgString = `<svg
                      viewBox="0 0 1100 ${containerHeight}"
                      width="1100"
                      height="${containerHeight}"
                      style="width:1100px;height:${containerHeight}px;position: relative;"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink">
                        <path class="path-1" fill="none" d="${lineDef1}"></path>
                        <path class="path-2" fill="none" d="${lineDef2}"></path>
                        <path class="path-3" fill="none" d="${lineDef3}"></path>
                        <path class="path-4" fill="none" d="${lineDef4}"></path>
                        <path stroke-dasharray="3" class="dashed-line" fill="none" d="${dashedLineDef}"></path>
                        <path class="path-5" class="dashed-line" fill="none" d="${dashedLineDef}"></path>
                    </svg>`

    this.processLine.innerHTML = svgString;

    let paths = [
      {
        el: $(".path-1"),
        length: $(".path-1").getTotalLength()
      },
      {
        el: $(".path-2"),
        length: $(".path-2").getTotalLength()
      },
      {
        el: $(".path-3"),
        length: $(".path-3").getTotalLength()
      },
      {
        el: $(".path-4"),
        length: $(".path-4").getTotalLength()
      },
      {
        el: $(".path-5"),
        length: $(".path-5").getTotalLength()
      },
    ]

    paths.forEach(function(path, index){
      if (index === 4) {
        path.el.style["stroke-dashoffset"] = 0;
        path.el.style["stroke-dasharray"] = path.length;
      } else {
        path.el.style["stroke-dashoffset"] = path.length;
        path.el.style["stroke-dasharray"] = path.length;
      }

      setTimeout(function(){
        path.el.classList.add("primed");
      }, 200);

      ScrollTrigger.create({
        trigger: path.el,
        start: "bottom bottom",
        onEnter: function() {
          if (index === 4) {
            path.el.style["stroke-dashoffset"] = path.length*-1;
          } else {
            path.el.style["stroke-dashoffset"] = 0;
          }
        }
      });
    });
  }
}

let processContainer = $(".grid-process");

if(processContainer) {
  new TheProcess(processContainer);
}

export default TheProcess;