const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { SVGInjector } from '../../../../node_modules/@tanem/svg-injector/dist/svg-injector.esm.js';
import { gsap } from "../../../../node_modules/gsap/index.js";
import { ScrollTrigger } from "../../../../node_modules/gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

let mastheadMoonSet = $(".masthead-primary .masthead--moon-set");

if (mastheadMoonSet) {
  SVGInjector($(".masthead-primary img"),{
    afterAll(elementsLoaded) {
      let triggerOpt = {
        trigger: ".masthead-primary",
        end: "bottom top",
        scrub: 0.5
      }

      let tl1 = gsap.timeline({
        scrollTrigger: triggerOpt
      });

      tl1.from("#moon", {x: 0,y: 0})
      .to("#moon", {x: 15,y: 55, ease: "power.inOut"})
    }
  });
}