const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import lottie from "../../../../../../node_modules/lottie-web/build/player/lottie_light.js";

let reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;

function scrollTo(element) {
  let $header = $("header[role='banner']");
  let $anchorNav = $(".anchor-nav");
  let anchorNavHeight = 0;
  if ($anchorNav) {
    anchorNavHeight = $anchorNav.offsetHeight;
  }
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop

  if (!element) {
    return;
  }

  let headerOffset = 0 + $header.offsetHeight + anchorNavHeight;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: element.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};

let mastheadHome = $(".masthead-home--animation");
let scrollButton = $(".masthead-home__scroll");

if (scrollButton) {
  scrollButton.addEventListener("click", function(e) {
    e.preventDefault();
    scrollTo(this);
  })
}

if (mastheadHome) {
  let element;
  let mql = window.matchMedia('(min-width: 576px)');
  if (mql.matches) {
    element = $(".masthead-home__desktop-image");
  } else {
    element = $(".masthead-home__mobile-image");
  }

  let image = $("img:not(.init)", element);
  let init = $(".init", element);
  let src = image.getAttribute("src");
  let path = src.split("/");
  let filename = path[path.length - 1].split(".")[0];


  if (!reducedMotion) {
    let animation = lottie.loadAnimation({
      container: element,
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: `/images/brandextract.com/animations/${filename}.json`
    });

    animation.addEventListener("data_ready", function(){
      element.classList.add("loaded");

      if (init) {
        init.classList.add("hidden");
      }
      image.classList.add("hidden");
    })
  } else {
    let allInit = $$(".init", mastheadHome);
    let allImages = $$("img:not(.init)", mastheadHome);

    allInit.forEach(image => {
      image.classList.add("hidden");
    });

    allImages.forEach(image => {
      image.classList.remove("hidden");
    });
  }
}