const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import A11YSlider from "../../../../node_modules/a11y-slider/dist/a11y-slider.esm.js";

let sliders = $$(".slider-default");

function throttle(func, wait = 100) {
  let timer = null;
  return function(...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args);
        timer = null;
      }, wait);
    }
  };
}

if (sliders.length > 0) {

  sliders.forEach(slider => {

    let previous = $(".slider-controls .a11y-slider-prev", slider);
    let next = $(".slider-controls .a11y-slider-next", slider);
    let countDisplay = $(".slide-counter .current", slider);
    let totalDisplay = $(".slide-counter .total", slider);

    let sliderInstance = new A11YSlider($(".slider-default__slider", slider), {
        slidesToShow: 1,
        arrows: false,
        dots: false,
        responsive: {
          768: {
            slidesToShow: 2
          },
          1024: {
            slidesToShow: 3
          },
        }
      });

      let slideCount = sliderInstance.slides.length;
      let changingShowCount = sliderInstance.options.slidesToShow;
      let currentSlide = sliderInstance.activeSlide;
      let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);

      previous.setAttribute("disabled","disabled");
      totalDisplay.textContent = Math.ceil(slideCount/changingShowCount);
      countDisplay.textContent = Math.ceil((currentIndex+1)/changingShowCount);

      const throttled = throttle(function() {
        if (changingShowCount !== sliderInstance.options.slidesToShow) {
          changingShowCount = sliderInstance.options.slidesToShow;
          totalDisplay.textContent = Math.ceil(slideCount/changingShowCount);
        }
      }, 500);
      window.addEventListener('resize', throttled);

      next.addEventListener("click", function() {
        let slideShowCount = sliderInstance.options.slidesToShow;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);

        //sliderInstance.scrollToSlide(14);
        if (currentIndex + slideShowCount < slideCount) {
          sliderInstance.scrollToSlide(currentIndex + slideShowCount);
        }
      }, false);

      previous.addEventListener("click", function() {
        let slideShowCount = sliderInstance.options.slidesToShow;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);
        let pushAmount = slideShowCount;
        let potentialOffset = currentIndex % slideShowCount;
        if (potentialOffset !== 0) {
          pushAmount = potentialOffset;
        }

        if (currentIndex - pushAmount >= 0) {
          sliderInstance.scrollToSlide(currentIndex - pushAmount);
        } else {
          sliderInstance.scrollToSlide(0);
        }
      }, false);
    
      $(".slider-default__slider", slider).addEventListener("afterChange", function(e) {
        let slideCount = sliderInstance.slides.length;
        let currentSlide = sliderInstance.activeSlide;
        let currentIndex = Array.from(currentSlide.parentNode.children).indexOf(currentSlide);
        let slideShowCount = sliderInstance.options.slidesToShow;
        let potentialPage = Math.floor((currentIndex)/slideShowCount) + 1;

        if (slideCount - (currentIndex+1) < slideShowCount && currentIndex % slideShowCount !== 0) {
          potentialPage = potentialPage + 1;
        }

        countDisplay.textContent = potentialPage;

        if (currentIndex === 0) {
          previous.setAttribute("disabled","disabled");
        } else {
          previous.removeAttribute("disabled");
        }

        if (currentIndex + slideShowCount >= slideCount) {
          next.setAttribute("disabled","disabled");
        } else {
          next.removeAttribute("disabled");
        }
      })
  });
}