const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let fileFields = $$(".file-label");

if (fileFields.length > 0) {
  fileFields.forEach(fields => {
    let file = $(".file", fields);
    let fileDisplay = $(".file-display", fields);

    file.addEventListener("change", function(e){
      let value = file.value;
      let path = value.split("\\");
      let filename = path[path.length - 1];
      fileDisplay.value = filename;
    })
  });
}