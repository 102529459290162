const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import A11YSlider from "../../../../../../node_modules/a11y-slider/dist/a11y-slider.esm.js";

let sliders = $$(".grid-logos__slider");

if (sliders.length > 0) {

  sliders.forEach(slider => {
    let slides = Array.prototype.slice.call(slider.children);
    let i,j,x,temparray;

    for (i=0,j=slides.length; i<j; i+=4) {
      let slideWrapper = document.createElement('div');
      slideWrapper.setAttribute("class", "grid-logos__slide");
      slider.appendChild(slideWrapper);

      for (x=0;x < 4; x++) {
        if((x+i) < slides.length) {
          slideWrapper.appendChild(slides[x+i]);
        }
      }
    }

    new A11YSlider(slider, {
      slidesToShow: 1,
      arrows: false,
      responsive: {
        1024: {
          slidesToShow: Math.ceil(slides.length/4)
        },
      }
    });
  });
}
