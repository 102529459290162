const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

import "./polyfills.js";
// Other imports (collections, entities, ...)
import "./imports.js";

// Application's Libraries.
import "./components/animation.js";
import "./components/media.js";
import "./components/share-tool.js";
import "./components/tables.js";
import "./components/file-field.js";

document.addEventListener('click', function (event) {
  let closest = event.target.closest(".clickable");

  if (!closest) return;
  if (event.target.nodeName === "A") return;

  event.preventDefault();
  $("a",closest).click();
}, false);


// window.addEventListener('load', (event) => {
//    // Had to wait for page to load so the header has dimension applied.
//    var fragment = window.location.hash;
//    if (!fragment) return;

//    scrollTo(fragment);
// });

window.onload = function() {
  var fragment = window.location.hash;
  if (!fragment) return;

  setTimeout(function() {scrollTo(fragment);}, 500)

}

window.addEventListener('hashchange', function(e) {
  e.preventDefault();

  var fragment = window.location.hash;
   if (!fragment) return;

   scrollTo(fragment);
}, false);

function scrollTo(id) {
  let $el = $(id);
  let $header = $("header[role='banner']");
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  let anchorNavHeight = 0;
  let anchorNav = $(".anchor-nav");

  if (!$el) {
    return;
  }

  if(anchorNav) {
    anchorNavHeight = anchorNav.offsetHeight;
  }

  let headerOffset = 0 + $header.offsetHeight + anchorNavHeight;
  if ($("body").classList.contains("samadmin")) {
    headerOffset = headerOffset + 97;
  }

  window.scroll({
    top: $el.getBoundingClientRect()["top"] + scrollTop
          - headerOffset
          - 45 /* Extra padding for visual look */,
    behavior: 'smooth'
  });
};