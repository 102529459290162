const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);

let shareTool = $(".share-tool");

if (shareTool) {
  let url = document.location.href;
  let title = document.title;

  $(".share-tool .icon-facebook").setAttribute("href",`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&t=${encodeURIComponent(title)}`);
  $(".share-tool .icon-twitter").setAttribute("href",`https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`);
  $(".share-tool .icon-linkedin").setAttribute("href",`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`);
}