// The imports will be hosted to the root "node_modules",
// and this package is under "@collections/tabs".

const BACKSPACE = 8;
const TAB = 9;
const ENTER = 13;
const SHIFT = 16;
const ESCAPE = 27;
const SPACE = 32;
const PAGE_UP = 33;
const PAGE_DOWN = 34;
const END = 35;
const HOME = 36;
const ARROW_LEFT = 37;
const ARROW_UP = 38;
const ARROW_RIGHT = 39;
const ARROW_DOWN = 40;
const DELETE = 46;

const direction = {
  37: -1,
  38: -1,
  39: 1,
  40: 1,
};

// Tabs with Automatic Activation
// https://www.w3.org/TR/wai-aria-practices/examples/tabs/tabs-1/tabs.html
class A11yTab {
  constructor(tablist, options = {}) {
    this.tablist = tablist;
    this.orientation = tablist.getAttribute("aria-orientation") || "horizontal";
    const tabs = this.tabs = [];
    const panels = this.panels = [];
    const mobileButton = this.mobileButton = tablist.parentNode.querySelector("button");

    // Bind.
    this.onKeydown = this.onKeydown.bind(this);
    this.onKeyup = this.onKeyup.bind(this);
    this.onClick = this.onClick.bind(this);
    this.toggleTabs = this.toggleTabs.bind(this);
    this.focusEventHandler = this.focusEventHandler.bind(this);

    // Determine whether there should be a delay when user navigates with
    // the arrow keys, default is
    this.delay = tablist.getAttribute("data-delay") || 0;

    const hash = window.location.hash;

    mobileButton.addEventListener("click", this.toggleTabs, false);

    let activeTab;
    tablist.querySelectorAll(`[role="tab"]`).forEach((tab, index) => {
      tabs.push(tab);
      const panelId = tab.getAttribute("aria-controls");
      panels.push(document.getElementById(panelId));

      const active = hash === `#${ panelId }`;
      if (active) {
        activeTab = tab;
      }

      tab.addEventListener("click", this.onClick, false);
      tab.addEventListener("keydown", this.onKeydown, false);
      tab.addEventListener("keyup", this.onKeyup, false);
    });

    if (activeTab) {
      this.activateTab(activeTab);
    }
  }

  /**
   * Click event listener
   *
   * Toggle tabs when on mobile
   *
   * @param {object} event
   * @return void
   */
  toggleTabs(event) {
    const button = event.target;
    const buttonWrapper = button.parentNode;

    if (buttonWrapper.getAttribute("open") === "open") {
      buttonWrapper.removeAttribute("open");
    } else {
      buttonWrapper.setAttribute("open","open");
    }
  }

  /**
   * Click event listener
   *
   * When a tab is clicked, activateTab is fired to activate it
   *
   * @param {object} event
   * @return void
   */
  onClick(event) {
    const tab = event.target;
    this.activateTab(tab, false);
    this.tablist.parentNode.removeAttribute("open");

    const href = tab.getAttribute("href");
    if (href) {
      event.preventDefault();
    }
  }

  /**
   * Keydown event listener
   *
   * Handle keydown on tabs
   *
   * @param {object} event
   * @return void
   */
  onKeydown(event) {
    const { keyCode: key } = event;

    const codes = {
      [END]: () => {
        event.preventDefault();
        this.activateTab(this.tabs[this.tabs.length - 1]);
      },
      [HOME]: () => {
        event.preventDefault();
        this.activateTab(this.tabs[0]);
      },
      [PAGE_UP]: () => this.determineOrientation(event),
      [PAGE_DOWN]: () => this.determineOrientation(event),
      // Up and down are in keydown because we need to prevent page scroll >:)
      [ARROW_UP]: () => this.determineOrientation(event),
      [ARROW_DOWN]: () => this.determineOrientation(event),
      default: () => false,
    };

    return (codes[key] || codes.default)();
  }

  /**
   * Keyup event listener
   *
   * Handle keyup on tabs
   *
   * @param {object} event
   * @return void
   */
  onKeyup(event) {
    const { keyCode: key, target } = event;
    // const selected = target.getAttribute("aria-selected") === "true";

    const codes = {
      [ARROW_LEFT]: () => this.determineOrientation(event),
      [ARROW_RIGHT]: () => this.determineOrientation(event),
      // [DELETE]: () => selected && this.determineDeletable(event),
      // [BACKSPACE]: () => selected && this.determineDeletable(event),
      default: () => false,
    };

    return (codes[key] || codes.default)();
  }

  /**
   * Determine orientation
   *
   * When a tablist's aria-orientation is set to vertical, only up and down
   * arrow should function. In all other cases only left and right arrow
   * function.
   *
   * @param {object} event
   */
  determineOrientation(event) {
    const { keyCode: key } = event;
    const vertical = "vertical" === this.orientation;
    let proceed = false;

    if (vertical && (key === PAGE_UP || key === PAGE_DOWN || key === ARROW_UP || key === ARROW_DOWN)) {
      event.preventDefault();
      proceed = true;
    } else if (key === ARROW_LEFT || key === ARROW_RIGHT) {
      proceed = true;
    }

    if (proceed) {
      this.switchTabOnArrowPress(event);
    }
  }

  /**
   * Switch tab on arrow press
   *
   * Either focus the next, previous, first, or last tab depening on key
   * pressed
   *
   * @param {object} event
   * @return void
   */
  switchTabOnArrowPress(event) {
    const { target, keyCode: key } = event;
    const tabs = this.tabs;

    tabs.forEach(tab => tab.addEventListener("focus", this.focusEventHandler));

    if (direction[key]) {
      const index = this.tabs.indexOf(target);
      if (index !== undefined) {
        if (this.tabs[index + direction[key]]) {
          this.tabs[index + direction[key]].focus();
        } else if (key === ARROW_LEFT || key === PAGE_UP || key === ARROW_UP) {
          this.focusLastTab();
        } else if (key === ARROW_RIGHT || key === PAGE_DOWN || key === ARROW_DOWN) {
          this.focusFirstTab();
        }
      }
    }
  }

  /**
   * Activate tab
   *
   * Activates any given tab panel
   *
   * @param  {object} tab
   * @param  {boolean} [setFocus=true]
   * @return void
   */
  activateTab(tab, setFocus = true) {
    // Deactivate all other tabs
    this.deactivateTabs();

    tab.setAttribute("tabindex", 0);
    // Set the tab as selected
    tab.setAttribute("aria-selected", "true");
    // Get the value of aria-controls (which is an ID)
    const panelId = tab.getAttribute("aria-controls");
    const panel = document.getElementById(panelId);

    // Remove hidden attribute from tab panel to make it visible
    // panel.removeAttribute("aria-hidden");
    panel.setAttribute("open","open");
    this.mobileButton.textContent = tab.textContent;

    // Set focus when required
    if (setFocus) {
      tab.click();
    } else {
      history.replaceState(null, null, `#${ panel.id}`);
    }

    const event = new CustomEvent("change", {
      detail: {
        currentTab: tab,
        currentIndex: this.tabs.indexOf(tab),
      }
    });
    this.tablist.dispatchEvent(event);
  }

  /**
   * Deactivate tabs
   *
   * Deactivate all tabs and tab panels
   *
   * @return void
   */
  deactivateTabs() {
    this.tabs.forEach(tab => {
      tab.setAttribute("tabindex", -1);
      tab.setAttribute("aria-selected", "false");
      tab.removeEventListener("focus", this.focusEventHandler);
    });

    this.panels.forEach(panel => {
      // panel.setAttribute("aria-hidden", "true");
      panel.removeAttribute("open");
    });
  }

  /**
   * Focus first tab
   *
   * @return
   */
  focusFirstTab() {
    return this.tabs[0].focus();
  }


  /**
   * Focus last tab
   *
   * @return
   */
  focusLastTab() {
    return this.tabs[this.tabs.length - 1].focus();
  }

  /**
   * Focus event handler
   *
   * @param {object} event
   * @return void
   */
  focusEventHandler(event) {
    const { target } = event;

    setTimeout(() => {
      this.checkTabFocus(target);
    }, this.delay);
  }

  /**
   * Check tab focus
   *
   * Only activate tab on focus if it still has focus after the delay
   *
   * @param {object} target
   * @return void
   */
  checkTabFocus(target) {
    const focused = document.activeElement;

    if (target === focused) {
      this.activateTab(target, false);
    }
  }
}

/** @type {NodeListOf<HTMLElement>} */
const tabsCollections = document.querySelectorAll("[role=\"tablist\"");
for (let i = 0, count = tabsCollections.length; i < count; i++) {
  new A11yTab(tabsCollections[i], {

  });
}

export default A11yTab;
