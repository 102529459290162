const $ = (selector, parent = document) => parent.querySelector(selector);
const $$ = (selector, parent = document) => parent.querySelectorAll(selector);
import { ScrollTrigger } from "../../../node_modules/gsap/ScrollTrigger.js";

import lottie from "../../../node_modules/lottie-web/build/player/lottie_light.js";

let lottieAnimations = $$(".load-animation, .load-video");
let reducedMotion = window.matchMedia("(prefers-reduced-motion: reduce)").matches;


lottieAnimations.forEach(element => {

  let section = element.parentNode.closest(".section");
  let image = $("img:not(.init)", element);
  let init = $("img.init", element);
  let src = image.getAttribute("src");
  let jsonScript = image.getAttribute("data-lottie");
  let path = src.split("/");
  let filename = path[path.length - 1].split(".")[0];

  ScrollTrigger.create({
    trigger: element,
    start: "top bottom",
    onEnter: function() {
      if(!element.classList.contains("loaded")) {

        if (element.classList.contains("load-animation")) {
          let path = `/images/brandextract.com/animations/${filename}.json`;
          if (jsonScript && jsonScript !== "$lottie") {
            path = jsonScript;
          }

          let animation = lottie.loadAnimation({
            container: element,
            renderer: "svg",
            loop: false,
            autoplay: false,
            path: path
          })

          animation.addEventListener("data_ready", function(){
            element.classList.add("loaded");
            if (init) {
              init.classList.add("hidden");
            }
            image.classList.add("hidden");

            if (!reducedMotion) {
              ScrollTrigger.create({
                trigger: $("svg",element),
                start: "bottom bottom",
                onEnter: function() {
                  animation.play();
                }
              })
            } else {
              animation.goToAndStop(animation.totalFrames - 1, true);
            }
          })
        } else {
          element.innerHTML = `
            <video autoplay="true" muted="true" playsinline="true" width="1100" height="620" poster="${src}" tabindex="0">
              <source type="video/mp4" src="/images/brandextract.com/animations/${filename}.mp4">
            </video>`;
        }
      }
    }
  });
});